<template>
  <div class="bigbox">

    <div class="nav">
      <van-nav-bar title="订单记录"
                   left-arrow
                   @click-left="onClickLeft" />
    </div>
    <div class="orderbox"
         v-show="!active">
      <div class="order"
           v-for="(item, index) in fillit"
           :key="index">
        <div class="flex">
          <div>{{item.carType}}出行</div>
          <!-- <div :style="{'color':(item.status=='已取消'?'#aaa':'')}">{{item.status}}</div> -->
          <div style="color:#F08080"
               v-show="item.status=='已取消'">{{item.status}}</div>
          <div style="color:blue"
               @click="go(item.orderId)"
               v-show="item.status=='已完成'">{{item.status}}</div>
          <div style="color:#00BFFF"
               v-show="item.status=='进行中'">{{item.status}}</div>
          <div @click="gopay"
               style="color:#DC143C"
               v-show="item.status=='待支付'">{{item.status}}</div>

          <!-- <span :style="color:item.status== '已取消' ? '#F08080': '', item.status=='已完成'? 'blue': '',item.status=='进行中'? '#00BFFF': '',item.status=='待支付'? '#DC143C': ''">{{item.status}}</span> -->
        </div>
        <div class="flex2"
             @click="cs(item.status,item.orderId)">
          <div class="flex2box">
            <img style="width:12px;height:12px;color:#eee;margin-top:5px"
                 src="../assets/images/appointment.png"
                 alt />
            <span style="margin-left:10px">{{item.createTime}}</span>
            <div class="input">
              <span class="yq">11</span>
              <!-- <img  class="yq" src="../assets/images/dinw.png" alt=""> -->
              <span style="margin-left:7px">{{item.startName}}</span>
            </div>
            <div class="input">
              <span class="yellow">11</span>
              <!-- <img  class="yellow" src="../assets/images/2.png" alt=""> -->
              <span style="margin-left:7px; width:100px;">{{item.endName}}</span>
            </div>
          </div>
          <div style="margin-top:10px">
            <span style="color:red;">￥{{item.totalFee}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="twobox"
         v-show="active">
      <div class="two">
        <img style="width:150px"
             src="../assets/images/noOrder.png"
             alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { record_api } from '../http/api/price'
import { Toast } from 'vant'
export default {
  name: 'TaxiH5Order',

  data () {
    return {
      fillit: [],
      carConstance: null,
      statusMap: null,
      token: '',
      active: false
    }
  },

  mounted () {

    var carConstance = new Map()
    carConstance.set('4', '曹操')
    carConstance.set('3', '神州')
    carConstance.set('7', '红旗')
    carConstance.set('11', 'T3')
    carConstance.set('5', '首汽')
    this.carConstance = carConstance

    var statusMap = new Map()
    statusMap.set(1, '已完成')
    statusMap.set(2, '已取消')
    statusMap.set(3, '进行中')
    statusMap.set(6, '待支付')
    this.statusMap = statusMap
  },
  created () {
    this.orderbox()
  },

  methods: {
    cs (a, e) {
      if (a == '已完成') {
        this.$router.push({ path: '/order_det', query: { orderid: e } });

      } else if (a == '待支付') {
        this.$router.push({
          path: '/payment'
        })
      }

    },
    //待支付
    gopay () {
      this.$router.push({
        path: '/payment'
      })
    },
    //跳转
    go (e) {
      console.log(e);
      this.$router.push({ path: '/order_det', query: { orderid: e } });
    },
    async orderbox () {
      this.token = JSON.parse(sessionStorage.getItem('token')).token
      console.log(this.token)
      let res = await record_api({
        token: this.token,
      })
      console.log(res)
      if (res.code == 200) {
        console.log(res.data.length);
        console.log(this.statusMap)
        this.fillit = res.data
        this.fillit.forEach((v) => {
          v.status = this.statusMap.get(v.status)
          v.carType = this.carConstance.get(v.carType)
          if (v.totalFee == null) {
            v.totalFee = '0.00'
          }
        })
        if (res.data.length == 0) {
          this.active = true
        } else {
          this.active = false

        }
      }
    },
    onClickLeft () {
      window.history.back(-1)
    },
    onClickRight () {
      Toast('按钮')
    },

  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  background: #eee;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .orderbox {
    margin: 0 15px;
    flex: 1;

    overflow-y: scroll;

    .order {
      margin-top: 10px;
      background: #fff;
      width: 95%;
      margin-left: 2.5%;
      height: 110px;
      border-radius: 15px;
      .flex {
        display: flex;
        justify-content: space-between;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 15px;
        padding-top: 10px;
      }
      .flex2 {
        display: flex;
        justify-content: space-between;
        margin-right: 20px;
        .flex2box {
          line-height: 23px;
          margin-left: 20px;
          margin-top: 5px;
        }
      }
      .input {
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .yq {
          width: 15px;
          height: 15px;

          background: #62ce62;
          border-radius: 50%;
          color: #62ce62;
        }
        .yellow {
          width: 15px;
          height: 15px;
          background: #ffb540;
          border-radius: 50%;
          color: #ffb540;
        }
      }
    }
  }
  .twobox {
    width: 100%;
    height: 100%;
    .two {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30%;
    }
  }
}
</style>